<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>
        <div class="text-start mn-profile">
          <span class="text-root mini-h">ลงทะเบียนอาสาสมัครเทคโนโลยีดิจิทัล<br>Smart City X</span>
        </div>

        <div class="card-body mt-2 white">
          <div class="p-10">
            <tabs v-model="active">
              <tab>QR Code</tab>
              <tab>ข้อมูลส่วนตัว</tab>
              <tab>ประวัติ</tab>
            </tabs>
          </div>
          <tab-panels v-model="active">
            <div class="mb-47">
              <tab-panel>
                <QRCode></QRCode>
              </tab-panel>
              <tab-panel>
                <Profile_c></Profile_c>
              </tab-panel>
              <tab-panel>
                <History></History>
              </tab-panel>
            </div>
          </tab-panels>
        </div>

        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import TabPanels from "@/components/TabPanels";
import TabPanel from "@/components/TabPanel";
import Profile_c from "@/components/Profile_c";
import History from "@/components/History";
import QRCode from "@/components/QRCode";
import { defineComponent, onMounted, inject, ref } from "vue";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  components: {
    Header,
    Tabs,
    Tab,
    TabPanels,
    TabPanel,
    Profile_c,
    QRCode,
    History
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const active = ref(0);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {
      close,
      active,
    };
  },
});
</script>

<style scoped>
.mn-profile {
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}
.mini-h {
  font-size: 20px;
}
</style>
